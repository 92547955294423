import Author from '../Author'

export const Author_Ruin: Author = {
    name: 'Ruin',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/profiles/76561197993764889'
        }
    ]
}
