import Author from '../Author'

export const Author_HellCross: Author = {
    name: 'HellCross',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/profiles/76561198005623257'
        }
    ]
}
