import imageNotFound from '../assets/img/image_not_found.png'

import Class_2andb from '../assets/img/portraits/2andb_portrait_roster.png'
import Class_Abomination from '../assets/img/portraits/abomination_portrait_roster.png'
import Class_Acolyte from '../assets/img/portraits/acolyte_portrait_roster.png'
import Class_Aegis from '../assets/img/portraits/aegis_portrait_roster.png'
import Class_Alchemist from '../assets/img/portraits/alchemist_portrait_roster.png'
import Class_Alonne from '../assets/img/portraits/alonne_portrait_roster.png'
import Class_Anoint from '../assets/img/portraits/anoint_portrait_roster.png'
import Class_Antiquarian from '../assets/img/portraits/antiquarian_portrait_roster.png'
import Class_Apostate from '../assets/img/portraits/apostate_portrait_roster.png'
import Class_Arbalest from '../assets/img/portraits/arbalest_portrait_roster.png'
import Class_Arcanist from '../assets/img/portraits/arcanist_portrait_roster.png'
import Class_Arsonist from '../assets/img/portraits/arsonist_portrait_roster.png'
import Class_Beastmaster from '../assets/img/portraits/beastmaster_portrait_roster.png'
import Class_Blackguard from '../assets/img/portraits/blackguard_portrait_roster.png'
import Class_Blackswordman from '../assets/img/portraits/blackswordman_portrait_roster.png'
import Class_BluesmanBet from '../assets/img/portraits/bluesman_bet_portrait_roster.png'
import Class_Bogatyr from '../assets/img/portraits/bogatyr_portrait_roster.png'
import Class_BountyHunter from '../assets/img/portraits/bounty_hunter_portrait_roster.png'
import Class_ButcherBird from '../assets/img/portraits/butcher_bird_portrait_roster.png'
import Class_Cannoneer from '../assets/img/portraits/cannoneer_portrait_roster.png'
import Class_Cataphract from '../assets/img/portraits/cataphract_portrait_roster.png'
import Class_Chaplain1 from '../assets/img/portraits/chaplain1_portrait_roster.png'
import Class_ChoirHunter from '../assets/img/portraits/choir_hunter_portrait_roster.png'
import Class_ClericErrant from '../assets/img/portraits/cleric_errant_portrait_roster.png'
import Class_Commandant from '../assets/img/portraits/commandant_portrait_roster.png'
import Class_Commissar from '../assets/img/portraits/commissar_portrait_roster.png'
import Class_Cook from '../assets/img/portraits/cook_portrait_roster.png'
import Class_Corsair from '../assets/img/portraits/corsair_portrait_roster.png'
import Class_Courtesan from '../assets/img/portraits/courtesan_portrait_roster.png'
import Class_Crusader from '../assets/img/portraits/crusader_portrait_roster.png'
import Class_Darkwraith from '../assets/img/portraits/darkwraith_portrait_roster.png'
import Class_Deovolente from '../assets/img/portraits/deovolente_portrait_roster.png'
import Class_Dragonslayers from '../assets/img/portraits/dragonslayers_portrait_roster.png'
import Class_Duchess from '../assets/img/portraits/duchess_portrait_roster.png'
import Class_Duelist from '../assets/img/portraits/duelist_portrait_roster.png'
import Class_Enigma from '../assets/img/portraits/enigma_portrait_roster.png'
import Class_Eternal from '../assets/img/portraits/eternal_portrait_roster.png'
import Class_Exorcist from '../assets/img/portraits/exorcist_portrait_roster.png'
import Class_Falconer from '../assets/img/portraits/falconer_portrait_roster.png'
import Class_Fawn from '../assets/img/portraits/fawn_portrait_roster.png'
import Class_FishOnmyoji from '../assets/img/portraits/fish_onmyoji_portrait_roster.png'
import Class_Flagellant from '../assets/img/portraits/flagellant_portrait_roster.png'
import Class_Fleshgolem from '../assets/img/portraits/fleshgolem_portrait_roster.png'
import Class_Forsaken from '../assets/img/portraits/forsaken_portrait_roster.png'
import Class_Fury from '../assets/img/portraits/fury_portrait_roster.png'
import Class_Golem from '../assets/img/portraits/golem_portrait_roster.png'
import Class_GraveRobber from '../assets/img/portraits/grave_robber_portrait_roster.png'
import Class_GroveTender from '../assets/img/portraits/grove_tender_portrait_roster.png'
import Class_Harbinger from '../assets/img/portraits/harbinger_portrait_roster.png'
import Class_Harlot from '../assets/img/portraits/harlot_portrait_roster.png'
import Class_Heiress from '../assets/img/portraits/heiress_portrait_roster.png'
import Class_Hellion from '../assets/img/portraits/hellion_portrait_roster.png'
import Class_Herald from '../assets/img/portraits/herald_portrait_roster.png'
import Class_Highwayman from '../assets/img/portraits/highwayman_portrait_roster.png'
import Class_Hollow from '../assets/img/portraits/hollow_knight_portrait_roster.png'
import Class_HollowKnight from '../assets/img/portraits/hollow_portrait_roster.png'
import Class_Hood from '../assets/img/portraits/hood_portrait_roster.png'
import Class_Houndmaster from '../assets/img/portraits/houndmaster_portrait_roster.png'
import Class_Hqclaimants from '../assets/img/portraits/hqclaimants_portrait_roster.png'
import Class_Ironbuddha from '../assets/img/portraits/iron_man_portrait_roster.png'
import Class_Ironclad from '../assets/img/portraits/ironbuddha_portrait_roster.png'
import Class_IronMan from '../assets/img/portraits/ironclad_portrait_roster.png'
import Class_Janissary from '../assets/img/portraits/janissary_portrait_roster.png'
import Class_Jester from '../assets/img/portraits/jester_portrait_roster.png'
import Class_JoanofArc from '../assets/img/portraits/JoanofArc_portrait_roster.png'
import Class_Keeper from '../assets/img/portraits/keeper_portrait_roster.png'
import Class_KegHunter from '../assets/img/portraits/keg_hunter_portrait_roster.png'
import Class_Kheir from '../assets/img/portraits/kheir_portrait_roster.png'
import Class_Kratos from '../assets/img/portraits/kratos_portrait_roster.png'
import Class_Lamia from '../assets/img/portraits/lamia_portrait_roster.png'
import Class_Leper from '../assets/img/portraits/leper_portrait_roster.png'
import Class_Librarian from '../assets/img/portraits/librarian_portrait_roster.png'
import Class_Lordblade from '../assets/img/portraits/lordblade_portrait_roster.png'
import Class_Lucia from '../assets/img/portraits/lucia_portrait_roster.png'
import Class_Mambo from '../assets/img/portraits/mambo_portrait_roster.png'
import Class_ManAtArms from '../assets/img/portraits/man_at_arms_portrait_roster.png'
import Class_Mender from '../assets/img/portraits/mender_portrait_roster.png'
import Class_Milkmaid from '../assets/img/portraits/milkmaid_portrait_roster.png'
import Class_Minstrel from '../assets/img/portraits/minstrel_portrait_roster.png'
import Class_Monk from '../assets/img/portraits/monk_portrait_roster.png'
import Class_Musketeer from '../assets/img/portraits/musketeer_portrait_roster.png'
import Class_NbbOwlwing from '../assets/img/portraits/nbb_owlwing_portrait_roster.png'
import Class_Occultist from '../assets/img/portraits/occultist_portrait_roster.png'
import Class_Offering from '../assets/img/portraits/offering_portrait_roster.png'
import Class_Omenseeker from '../assets/img/portraits/omenseeker_portrait_roster.png'
import Class_Paladin from '../assets/img/portraits/paladin_portrait_roster.png'
import Class_Philomath from '../assets/img/portraits/philomath_portrait_roster.png'
import Class_PlagueDoctor from '../assets/img/portraits/plague_doctor_portrait_roster.png'
import Class_Prince from '../assets/img/portraits/prince_portrait_roster.png'
import Class_Prodigy from '../assets/img/portraits/prodigy_portrait_roster.png'
import Class_Rescuer from '../assets/img/portraits/rescuer_portrait_roster.png'
import Class_Revenant from '../assets/img/portraits/revenant_portrait_roster.png'
import Class_Ringmaster from '../assets/img/portraits/ringmaster_portrait_roster.png'
import Class_Ronin from '../assets/img/portraits/ronin_portrait_roster.png'
import Class_Scourge from '../assets/img/portraits/scourge_portrait_roster.png'
import Class_Seer from '../assets/img/portraits/seer_portrait_roster.png'
import Class_Seraph from '../assets/img/portraits/seraph_portrait_roster.png'
import Class_Shieldbreaker from '../assets/img/portraits/shield_maiden_portrait_roster.png'
import Class_ShieldMaiden from '../assets/img/portraits/shieldbreaker_portrait_roster.png'
import Class_Shrapnel from '../assets/img/portraits/shrapnel_portrait_roster.png'
import Class_Siexiled from '../assets/img/portraits/siexiled_portrait_roster.png'
import Class_Sisters from '../assets/img/portraits/sisters_portrait_roster.png'
import Class_Slayer from '../assets/img/portraits/slayer_portrait_roster.png'
import Class_SnakeCharmer from '../assets/img/portraits/snake_charmer_portrait_roster.png'
import Class_Spacemarine1 from '../assets/img/portraits/spacemarine1_portrait_roster.png'
import Class_Stargazer from '../assets/img/portraits/stargazer_portrait_roster.png'
import Class_SteelDriver from '../assets/img/portraits/steel_driver_portrait_roster.png'
import Class_Succubus from '../assets/img/portraits/succubus_portrait_roster.png'
import Class_Tactician from '../assets/img/portraits/tactician_portrait_roster.png'
import Class_TheCondena from '../assets/img/portraits/the_condena_portrait_roster.png'
import Class_Thorn from '../assets/img/portraits/thorn_portrait_roster.png'
import Class_Thrall from '../assets/img/portraits/thrall_portrait_roster.png'
import Class_Tifa from '../assets/img/portraits/tifa_portrait_roster.png'
import Class_Tusk from '../assets/img/portraits/tusk_portrait_roster.png'
import Class_TwilightKnight from '../assets/img/portraits/twilight_knight_portrait_roster.png'
import Class_Ushiro from '../assets/img/portraits/ushiro_portrait_roster.png'
import Class_Vamp from '../assets/img/portraits/vamp_portrait_roster.png'
import Class_Veiled from '../assets/img/portraits/veiled_portrait_roster.png'
import Class_Vestal from '../assets/img/portraits/vestal_portrait_roster.png'
import Class_Viper from '../assets/img/portraits/viper_portrait_roster.png'
import Class_Voivode from '../assets/img/portraits/voivode_portrait_roster.png'
import Class_Wanderer from '../assets/img/portraits/wanderer_portrait_roster.png'
import Class_WarriorOfSunlight from '../assets/img/portraits/warrior_of_sunlight_portrait_roster.png'
import Class_Watcher from '../assets/img/portraits/watcher_portrait_roster.png'
import Class_Werewolf from '../assets/img/portraits/werewolf_portrait_roster.png'
import Class_Witcher from '../assets/img/portraits/witcher_portrait_roster.png'
import Class_Wolfknight from '../assets/img/portraits/wolfknight_portrait_roster.png'
import Class_Wraith from '../assets/img/portraits/wraith_ms_portrait_roster.png'
import Class_WraithMs from '../assets/img/portraits/wraith_portrait_roster.png'
import Class_Wretch from '../assets/img/portraits/wretch_portrait_roster.png'
import Class_Zenith from '../assets/img/portraits/zenith_portrait_roster.png'

const PortraitIndex: Map<string, string> = new Map([
    ['Class_2andb', Class_2andb],
    ['Class_Abomination', Class_Abomination],
    ['Class_Acolyte', Class_Acolyte],
    ['Class_Aegis', Class_Aegis],
    ['Class_Alchemist', Class_Alchemist],
    ['Class_Alonne', Class_Alonne],
    ['Class_Anoint', Class_Anoint],
    ['Class_Antiquarian', Class_Antiquarian],
    ['Class_Apostate', Class_Apostate],
    ['Class_Arbalest', Class_Arbalest],
    ['Class_Arcanist', Class_Arcanist],
    ['Class_Arsonist', Class_Arsonist],
    ['Class_Beastmaster', Class_Beastmaster],
    ['Class_Blackguard', Class_Blackguard],
    ['Class_Blackswordman', Class_Blackswordman],
    ['Class_BluesmanBet', Class_BluesmanBet],
    ['Class_Bogatyr', Class_Bogatyr],
    ['Class_BountyHunter', Class_BountyHunter],
    ['Class_ButcherBird', Class_ButcherBird],
    ['Class_Cannoneer', Class_Cannoneer],
    ['Class_Cataphract', Class_Cataphract],
    ['Class_Chaplain1', Class_Chaplain1],
    ['Class_ChoirHunter', Class_ChoirHunter],
    ['Class_ClericErrant', Class_ClericErrant],
    ['Class_Commandant', Class_Commandant],
    ['Class_Commissar', Class_Commissar],
    ['Class_Cook', Class_Cook],
    ['Class_Corsair', Class_Corsair],
    ['Class_Courtesan', Class_Courtesan],
    ['Class_Crusader', Class_Crusader],
    ['Class_Darkwraith', Class_Darkwraith],
    ['Class_Deovolente', Class_Deovolente],
    ['Class_Dragonslayers', Class_Dragonslayers],
    ['Class_Duchess', Class_Duchess],
    ['Class_Duelist', Class_Duelist],
    ['Class_Enigma', Class_Enigma],
    ['Class_Eternal', Class_Eternal],
    ['Class_Exorcist', Class_Exorcist],
    ['Class_Falconer', Class_Falconer],
    ['Class_Fawn', Class_Fawn],
    ['Class_FishOnmyoji', Class_FishOnmyoji],
    ['Class_Flagellant', Class_Flagellant],
    ['Class_Fleshgolem', Class_Fleshgolem],
    ['Class_Forsaken', Class_Forsaken],
    ['Class_Fury', Class_Fury],
    ['Class_Golem', Class_Golem],
    ['Class_GraveRobber', Class_GraveRobber],
    ['Class_GroveTender', Class_GroveTender],
    ['Class_Harbinger', Class_Harbinger],
    ['Class_Harlot', Class_Harlot],
    ['Class_Heiress', Class_Heiress],
    ['Class_Hellion', Class_Hellion],
    ['Class_Herald', Class_Herald],
    ['Class_Highwayman', Class_Highwayman],
    ['Class_HollowKnight', Class_HollowKnight],
    ['Class_Hollow', Class_Hollow],
    ['Class_Hood', Class_Hood],
    ['Class_Houndmaster', Class_Houndmaster],
    ['Class_Hqclaimants', Class_Hqclaimants],
    ['Class_IronMan', Class_IronMan],
    ['Class_Ironbuddha', Class_Ironbuddha],
    ['Class_Ironclad', Class_Ironclad],
    ['Class_Janissary', Class_Janissary],
    ['Class_Jester', Class_Jester],
    ['Class_JoanofArc', Class_JoanofArc],
    ['Class_Keeper', Class_Keeper],
    ['Class_KegHunter', Class_KegHunter],
    ['Class_Kheir', Class_Kheir],
    ['Class_Kratos', Class_Kratos],
    ['Class_Lamia', Class_Lamia],
    ['Class_Leper', Class_Leper],
    ['Class_Librarian', Class_Librarian],
    ['Class_Lordblade', Class_Lordblade],
    ['Class_Lucia', Class_Lucia],
    ['Class_Mambo', Class_Mambo],
    ['Class_ManAtArms', Class_ManAtArms],
    ['Class_Mender', Class_Mender],
    ['Class_Milkmaid', Class_Milkmaid],
    ['Class_Minstrel', Class_Minstrel],
    ['Class_Monk', Class_Monk],
    ['Class_Musketeer', Class_Musketeer],
    ['Class_NbbOwlwing', Class_NbbOwlwing],
    ['Class_Occultist', Class_Occultist],
    ['Class_Offering', Class_Offering],
    ['Class_Omenseeker', Class_Omenseeker],
    ['Class_Paladin', Class_Paladin],
    ['Class_Philomath', Class_Philomath],
    ['Class_PlagueDoctor', Class_PlagueDoctor],
    ['Class_Prince', Class_Prince],
    ['Class_Prodigy', Class_Prodigy],
    ['Class_Rescuer', Class_Rescuer],
    ['Class_Revenant', Class_Revenant],
    ['Class_Ringmaster', Class_Ringmaster],
    ['Class_Ronin', Class_Ronin],
    ['Class_Scourge', Class_Scourge],
    ['Class_Seer', Class_Seer],
    ['Class_Seraph', Class_Seraph],
    ['Class_ShieldMaiden', Class_ShieldMaiden],
    ['Class_Shieldbreaker', Class_Shieldbreaker],
    ['Class_Shrapnel', Class_Shrapnel],
    ['Class_Siexiled', Class_Siexiled],
    ['Class_Sisters', Class_Sisters],
    ['Class_Slayer', Class_Slayer],
    ['Class_SnakeCharmer', Class_SnakeCharmer],
    ['Class_Spacemarine1', Class_Spacemarine1],
    ['Class_Stargazer', Class_Stargazer],
    ['Class_SteelDriver', Class_SteelDriver],
    ['Class_Succubus', Class_Succubus],
    ['Class_Tactician', Class_Tactician],
    ['Class_TheCondena', Class_TheCondena],
    ['Class_Thorn', Class_Thorn],
    ['Class_Thrall', Class_Thrall],
    ['Class_Tifa', Class_Tifa],
    ['Class_Tusk', Class_Tusk],
    ['Class_TwilightKnight', Class_TwilightKnight],
    ['Class_Ushiro', Class_Ushiro],
    ['Class_Vamp', Class_Vamp],
    ['Class_Veiled', Class_Veiled],
    ['Class_Vestal', Class_Vestal],
    ['Class_Viper', Class_Viper],
    ['Class_Voivode', Class_Voivode],
    ['Class_Wanderer', Class_Wanderer],
    ['Class_WarriorOfSunlight', Class_WarriorOfSunlight],
    ['Class_Watcher', Class_Watcher],
    ['Class_Werewolf', Class_Werewolf],
    ['Class_Witcher', Class_Witcher],
    ['Class_Wolfknight', Class_Wolfknight],
    ['Class_Wraith', Class_Wraith],
    ['Class_WraithMs', Class_WraithMs],
    ['Class_Wretch', Class_Wretch],
    ['Class_Zenith', Class_Zenith]
])

interface PortraitProps {
    classKey: string
    className?: string
    style?: React.CSSProperties
}

export const Portrait = ({ classKey, className, style }: PortraitProps) => {
    return (
        <img
            alt="Hero Portrait"
            className={className}
            style={style}
            src={PortraitIndex.get(classKey) ?? imageNotFound}
        />
    )
}
