import Author from '../Author'

export const Author_Shay: Author = {
    name: 'Shay',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/id/shaylockholmes'
        }
    ]
}
