import Author from '../Author'

export const Author_Rehtael: Author = {
    name: 'Rehtael',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/profiles/76561198012886214'
        }
    ]
}
