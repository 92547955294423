import Author from '../Author'

export const Author_VoidSlime: Author = {
    name: 'Void Slime',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/id/Aavri'
        }
    ]
}
