import Author from '../Author'

export const Author_54NBB: Author = {
    name: '54NBB',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/id/54NBB'
        }
    ]
}
