import Author from '../Author'

export const Author_Snorlaxxo: Author = {
    name: 'Snorlaxxo',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/id/snorlaxxo'
        }
    ]
}
