import Author from '../Author'

export const Author_Roderick: Author = {
    name: 'Roderick',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/profiles/76561198120534999'
        }
    ]
}
