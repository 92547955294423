import Author from '../Author'

export const Author_Sasiji: Author = {
    name: 'Sasiji',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/profiles/76561198143986901'
        }
    ]
}
