import Author from '../Author'

export const Author_Cat: Author = {
    name: 'Cat',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/id/CattyCatty'
        }
    ]
}
