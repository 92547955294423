import styled from 'styled-components'

export const ModSourcesMain = styled.div`
    width: 100%;
`

export const ModSourceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`
