import Author from '../Author'

export const Author_EbonyBetty: Author = {
    name: 'EbonyBetty',
    links: [
        {
            name: 'Steam',
            link: 'https://steamcommunity.com/id/EbonyBetty'
        }
    ]
}
