import arrowDown from '../assets/img/arrowDown.png'

export const ArrowDownIcon = () => {
    return (
        <img
            src={arrowDown}
            alt="expand"
            style={{ width: '30px', height: '25px' }}
        />
    )
}
